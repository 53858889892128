import { useEffect, useState } from 'react';
import { API_URI, APP_IMG } from 'src/constants';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { GET } from 'src/utils/services';
import _ from 'lodash';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
// import Carousel from 'src/components/carousels';

const Header = dynamic(import('src/components/layouts/header'), { ssr: true });
const Footer = dynamic(import('src/components/layouts/footer'));
const Carousel = dynamic(() => import('src/components/carousels'), {
  ssr: false,
  loading: () => <>loading....</>
});

const Layout = ({ children, ads, sessionSevId }) => {
  const [navData, setNavData] = useState(null);
  const [dataSets, setDataSets] = useState([]);

  const router = useRouter();

  useEffect(async () => {
    const res = await fetch(`${API_URI}/api/v1.0/navigations/menu-nav`);
    const dataSetsAll = await GET('/api/datasets');
    if (!_.isEmpty(dataSetsAll)) {
      // console.log(dataSetsAll);
      setDataSets(_.find(dataSetsAll, { topic: 'ADS' }) || []);
    }
    const resJson = await res.json();
    if ('msg' in resJson) {
      setNavData(null);
    } else {
      setNavData(resJson);
    }
  }, []);

  useEffect(() => {
    let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    const addBtnClose = document.querySelector('.add-button-close');

    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = 'block';

      addBtn.addEventListener('click', e => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none';
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
      });
      addBtnClose.addEventListener('click', e => {
        addBtnClose.style.display = 'none';
      });
    });
  }, []);

  return (
    <>
      <AdsDefault className='container ads-default-billbord '>
        {!_.isEmpty(dataSets.dataSet) && (
          <>
            <Carousel>
              {dataSets.dataSet?.map((itemSet, index) => (
                <div key={index} className='carousel-center justify-center'>
                  <a
                    target='_blank'
                    tabIndex='-1'
                    rel='noreferrer noopener nofollow'
                    href={itemSet.key}
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'imagecover',
                        data: {
                          title: itemSet?.key,
                          heading: 'imagecover',
                          carousel: index + 1
                        }
                      })
                    }
                  >
                    <img src={itemSet.value} alt={itemSet.key} width='100%' height='100%' />
                  </a>
                </div>
              ))}
            </Carousel>
          </>
        )}
      </AdsDefault>

      <Header data={navData} sessionSevId={sessionSevId} />
      {children}
      <Footer data={navData} ads={ads} />
    </>
  );
};

const AdsDefault = styled.div`
  margin: 15px auto;
  position: relative;
  min-height: 250px;
  @media (max-width: 768px) {
    min-height: 191px;
  }
  @media (max-width: 480px) {
    min-height: 90px;
    padding: 0;
  }
  &.ads-default-billbord {
    width: auto;
    height: auto;
    max-width: 100%;
    overflow: hidden;
    img {
      width: auto !important;
      height: auto;
      max-width: 100%;
    }
  }
  .carousel-center {
    display: flex !important;
  }
  p {
    position: absolute;
    bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #00ff57;
    border: 2px solid #00ff57;
    padding: 5px;
    left: 50%;
    background: black;
    transform: translate(-50%, 0);
  }
`;

export default Layout;
