import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const APP_ENV = publicRuntimeConfig.NEXT_PUBLIC_APP_ENV || 'production';
export const API_URI = publicRuntimeConfig.NEXT_PUBLIC_API_URL || process.env.API_URL;
export const APP_URL = publicRuntimeConfig.NEXT_PUBLIC_APP_URL;
export const APP_IMG = publicRuntimeConfig.NEXT_PUBLIC_IMG;
export const NEXT_DAILYMOTION_ID = publicRuntimeConfig.NEXT_DAILYMOTION_ID;
export const SHOW_COMMEMORATE = publicRuntimeConfig.SHOW_COMMEMORATE || process.env.SHOW_COMMEMORATE;

export const isProd = APP_ENV.toLowerCase() === 'production';
export const IS_NO_ROBOT_INDEX = !isProd;
export const IS_SHOW_COOKIE = publicRuntimeConfig.IS_SHOW_COOKIE || process.env.IS_SHOW_COOKIE;

/* ----------------------------- PUBLIC_RUNTIME ------------------------------ */

// GTM
export const GTM_ID = publicRuntimeConfig.NEXT_PUBLIC_GTM_ID || process.env.NEXT_PUBLIC_GTM_ID;
// GSERVICE
export const GSERVICE_URL = publicRuntimeConfig.NEXT_PUBLIC_GSERVICE_URL || 'https://gservice.khobsanam.com';
// RECAPTCHA
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || publicRuntimeConfig.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const SECTION_THAILEAGUE = process.env.NEXT_PUBLIC_SECTION_THAILEAGUE || publicRuntimeConfig.NEXT_PUBLIC_SECTION_THAILEAGUE;
/* ----------------------------- SERVER_RUNTIME ------------------------------ */

export const CONTENT_DEFAULT = process.env.NEXT_SERVER_DEFAULT_ID || serverRuntimeConfig.NEXT_SERVER_DEFAULT_ID || 0;
export const RECAPTCHA_SECRET_KEY = process.env.NEXT_SERVER_RECAPTCHA_SECRET_KEY || serverRuntimeConfig.NEXT_SERVER_RECAPTCHA_SECRET_KEY;
// REDIS

export const REDIS_HOST = serverRuntimeConfig.REDIS_HOST;
export const REDIS_PASSWORD = serverRuntimeConfig.REDIS_PASSWORD;
export const REDIS_TTL = serverRuntimeConfig.REDIS_TTL;
/* ---------------------------- RECAPTCHA ---------------------------- */
// speech
export const NEXT_PUBLIC_URL_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_SOUND;
export const NEXT_PUBLIC_URL_PATH_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_PATH_SOUND;
export const NEXT_PUBLIC_WEB_NAME_SOUND = publicRuntimeConfig.NEXT_PUBLIC_WEB_NAME_SOUND;
export const NEXT_PUBLIC_ID_MOCK_SOUND = publicRuntimeConfig.NEXT_PUBLIC_ID_MOCK_SOUND;
export const NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND;
export const NEXT_PUBLIC_PLACEHOLDER_TIME_OUT = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TIME_OUT;

export const AUTH0_BASE_URL = serverRuntimeConfig.AUTH0_BASE_URL;
export const AUTH0_ISSUER_BASE_URL = serverRuntimeConfig.AUTH0_ISSUER_BASE_URL;
export const AUTH0_CLIENT_ID = serverRuntimeConfig.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = serverRuntimeConfig.AUTH0_CLIENT_SECRET;

export const WEB_NAME = publicRuntimeConfig.WEB_NAME;
export const NEXT_PUBLIC_LOGIN_COOKIE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_COOKIE_DATE || 1;
export const NEXT_PUBLIC_LOGIN_STATE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_STATE_DATE || 1;
export const NEXT_PUBLIC_SPEED_KEYPOINT = publicRuntimeConfig.NEXT_PUBLIC_SPEED_KEYPOINT;
