// import {
//   AiOutlineClockCircle
// } from 'react-icons/ai';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
// import Link from 'next/link';
import Image from 'src/components/image';
import { incrementCountview } from 'src/utils/increment-countview';
// import numeral from 'numeral';
import { usePageViews } from 'src/utils/helper';
import { FaPlay, FaRegCalendarAlt, FaRegEye } from 'react-icons/fa';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
dayjs.extend(buddhistEra);

const CardV = ({ data, showBlurb = false, badge = false, byline = true, showCountview = true, external = false, eventDataLayer }) => {
  const categoryNameTH = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  const [countView, setCountView] = useState(0);
  // const badgeDatalayer = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;

  useEffect(async () => {
    await incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateUpdate = dayjs(data?.published_at || data?.dateDefault)
    .locale('th')
    .format('DD MMM BBBB');
  // console.log(data)
  return (
    <a
      href={data.link}
      tabIndex='-1'
      title={data?.title}
      rel={external ? 'noreferrer noopener follow' : ''}
      target={external ? '_blank' : '_self'}
      className='card-wrapper'
      //! DATALAYER
      onClick={() =>
        sendDataLayerGlobal({
          ...eventDataLayer
        })
      }
    >
      <CardVStyle>
        <div className='card-v-thumb'>
          <div className='img-scale-animate'>
            <Image image={data?.image} title={data?.title} external={external} type='CARD_MEDIUM' />
          </div>
          {data?.is_video && (
            <div className='play-btn'>
              <FaPlay />
            </div>
          )}
        </div>
        {badge && <span className='badge'>{categoryNameTH}</span>}
        <div className='card-v-content'>
          <h3 className='card-v-content-title text-excerpt-2'>{data?.title}</h3>
          {showBlurb && <p className='text-excerpt-2'>{data?.blurb}</p>}
          <div className='border-style-full-v' />
          <div className='card-v-content-meta'>
            {byline && data.byline && (
              <div className='byline-card-v'>
                <span>{data.byline}</span>
              </div>
            )}
            <div className='date-count-card-v'>
              <FaRegCalendarAlt />
              <span className='date'>{dateUpdate}</span>
              {showCountview && (countView > 0 || data.pageviews > 0) && (
                <span className='pageview'>
                  <FaRegEye /> {usePageViews(countView) || usePageViews(data?.pageviews)}
                </span>
              )}
            </div>
          </div>
        </div>
      </CardVStyle>
    </a>
  );
};

const CardVStyle = styled.div`
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 3px;
  &:hover {
    h3 {
      color: #00c040;
    }
  }
  .card-v-thumb {
    position: relative;
    .img-scale-animate {
      position: relative;
      padding-right: 0;
      border: 1px solid #f5f5f5;
      border-radius: 3px 3px 0px 0px;
    }
    .play-btn {
      position: absolute;
      bottom: -2px;
      right: 25px;
      display: block;
      margin: 0 auto;
      z-index: 0;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      text-align: center;
      svg {
        color: #ffffff !important;
        font-size: 24px !important;
        padding: 3px 0 0 4px;
      }
      &::after {
        content: '';
        position: absolute;
        background: #00ff57;
        /* background: hsla(0,0%,72%,.588); */
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
        width: 50px;
        border-radius: 50px;
        height: 50px;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 44%;
        right: 0;
        z-index: -1;
      }
    }
  }

  .card-v-content {
    padding: 5px 15px;
    border: 1px solid #f5f5f5;
    border-radius: 0px 0px 3px 3px;
    @media (max-width: 768px) {
      padding: 5px 8px 5px 10px;
    }
    h3 {
      transition: 0.5s ease-out;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.75;
      margin: 0px;
      min-height: 60px;
      @media (max-width: 768px) {
        font-size: 16px;
        min-height: 48px;
      }
    }
    p {
      font-size: 16px;
    }
    .readmore {
      border: 1px solid rgb(0, 2, 28, 0.95);
      background-color: rgb(0, 2, 28, 0.95);
      border-radius: 3px;
      width: fit-content;
      padding: 5px 13px;
      color: #fff;
    }
    .border-style-full-v {
      border-top: 1px solid rgba(240, 240, 240);
      margin: 8px 0;
      @media (max-width: 768px) {
        margin: 4px 0;
      }
    }
    .card-v-content-meta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      svg {
        margin-right: 5px;
        margin-bottom: 2px;
      }
      svg,
      span {
        font-size: 12px;
        vertical-align: middle;
        color: rgb(0, 2, 28, 0.75);
      }
      .pageview {
        margin-left: 5px;
      }
      .byline-card-v {
        text-transform: uppercase;
        font-size: 12px;
        color: rgb(0, 2, 28, 0.75);
      }
      .pageview {
        svg {
          margin-left: 7px;
          margin-bottom: 2px;
        }
      }
      @media (max-width: 768px) {
        display: block;
        svg,
        span {
          font-size: 11px;
        }
        .byline-card-v {
          padding: 2px 0px;
          span {
            font-size: 10px;
          }
        }
      }
    }
  }
`;

export default CardV;
