import { GSERVICE_URL } from 'src/constants';
// import { WEB_NAME } from 'src/constants/layout';
import { fetchWithTimeout } from 'src/utils/fetch-with-timeout';

export const incrementCountview = async (data) => {
  let increase = 0;
  if ('pageviews' in data) {
    const params = {
      site: 'khobsanam',
      newsid: data.id
    };
    try {
      const response = await fetchWithTimeout(`${GSERVICE_URL}/cpvs?site=${encodeURIComponent(params.site)}&newsid=${encodeURIComponent(params.newsid)}`, {
        timeout: 1000
      });
      const countview = await response.json();
      increase = countview + data.pageviews;
    } catch (error) {
      console.warn('timeout :(');
    }
  }
  return increase;
};
