import Head from 'next/head';
import Aos from 'aos';
import PageError from 'pages/404';
import 'src/assets/sass/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Script from 'next/script';
// import { isRender } from 'src/utils/device';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';
import { GET } from 'src/utils/services';
import { Provider } from 'react-redux';
import { store } from 'src/redux/store';

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  if (pageProps?.statusCode >= 400) {
    return (
      <>
        <Provider store={store}>
          <PageError />
        </Provider>
      </>
    );
  }
  const [scriptBrowser, setScriptBrowser] = useState(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const getScriptBrowser = async () => {
    const scriptBrowser = await GET('/api/headerAds');
    if (!_.isEmpty(scriptBrowser)) {
      setScriptBrowser(scriptBrowser);
    }
  };
  useEffect(() => {
    getScriptBrowser();
    return () => {};
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1500 });
    return () => {};
  }, []);

  return (
    <>
      <Head>
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
        {/* ------------------------------ ADS : Adsense ----------------------------- */}
        {/* {isRender('mobile') && <script async src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1933163006117573'
          crossOrigin='anonymous' />} */}
        {/* ------------------------------ ScriptBrowser ----------------------------- */}
        {!isDesktopOrLaptop && !_.isEmpty(scriptBrowser) ? parse(`${scriptBrowser?.header_mobile}`) : ''}
        {isDesktopOrLaptop && !_.isEmpty(scriptBrowser) ? parse(`${scriptBrowser?.header_desktop}`) : ''}
      </Head>
      {/* -------------------------------- IZOOTO ------------------------------- */}
      <Script strategy='lazyOnload' src='https://cdn.izooto.com/scripts/25adcdc67f1ed5dc64e8fd288368005fbc9a1ab1.js' />
      <Script
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: 'window._izq = window._izq || []; window._izq.push(["init" ]);'
        }}
      />

      {/* -------------------------------- ADS : PPN ------------------------------- */}
      <Script strategy='lazyOnload' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
      <Script
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
        }}
      />

      {/* --------------------------------- Truehit -------------------------------- */}
      <Script
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
                (function() {
                  var ga1 = document.createElement('script'); 
                  ga1.type = 'text/javascript';
                  ga1.async = true;
                  ga1.src = "//lvs.truehits.in.th/dataa/t0031980.js";
                  var s = document.getElementsByTagName('script')[0]; 
                  s.parentNode.insertBefore(ga1, s);
                })();
                `
        }}
      />
      <>
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </>
    </>
  );
};

export default MyApp;
