import { FaFacebookF } from 'react-icons/fa';
import { ImYoutube2 } from 'react-icons/im';
import { APP_IMG, APP_URL } from 'src/constants';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
export const LOGO_192 = 'Icon-192.png';
export const LOGO_FAVICON = 'favicon.ico';
export const LOGO_APPLE = 'logo-apple-touch.png';
export const IMAGE_DEFAULT = `${APP_IMG}/default.jpg`; // default image
export const WEB_TITLE = 'ขอบสนาม - ข่าวฟุตบอล บอลวันนี้ คลิปบอล ผลบอลสด บอลไทย พรีเมียร์ลีก';
export const WEB_DESC = 'ขอบสนาม ผลบอล ข่าวบอล คลิปบอล ประวัตินักบอล รอบโลกกีฬา ฟุตบอลไทย ฟุตบอลโลก2018 ฟุตบอลต่างประเทศ ยูฟ่าแชมเปี้ยนลีก วิเคราะห์ฟุตบอล เบลล์ขอลสนาม';
export const WEB_KEYWORD = 'ขอบสนาม ผลบอล ข่าวบอล คลิปบอล ประวัตินักบอล รอบโลกกีฬา ฟุตบอลไทย ฟุตบอลโลก2018 ฟุตบอลต่างประเทศ ยูฟ่าแชมเปี้ยนลีก วิเคราะห์ฟุตบอล เบลล์ขอลสนาม';
export const WEB_NAME = 'khobsanam';
export const WEB_LOGO = 'logo.png';

export const TWITTER = {
  HANDLE: 'ขอบสนาม',
  CREATOR: '@Khobsanam',
  SITE: '@Khobsanam'
};

export const FACBOOK = {
  PAGE: '380377805483443'
};

/* ---------------------------------- THEME --------------------------------- */

export const THEME_WEB = '#00ff57';
export const THEME_TITLE = '#424242';
/* -------------------------------------------------------------------------- */
/*                                   FOOTER                                   */
/* -------------------------------------------------------------------------- */

export const WEB_URL = {
  WEB: `${APP_URL}/`,
  FACEBOOK: 'https://www.facebook.com/khobsanam/',
  YOUTUBE: 'https://www.youtube.com/channel/UCkN80VKrPiKYyz7ByBaRosQ',
  INSTAGRAM: 'https://www.instagram.com/khobsanam/'
};

export const ADDRESS_ORG = {
  CONTENT: 'บริษัท ขอบสนาม จำกัด 388/72 โครงการ Biz Galleria ถ. นวลจันทร์ แขวง คลองกุ่ม เขต บึงกุ่ม',
  PROVINCE: 'กรุงเทพฯ',
  REGION: 'TH',
  POSTCODE: '10230',
  COUNTRY: 'THAILAND'
};

export const SOCIAL_LIST = [
  { id: 1, icon: <FaFacebookF />, link: WEB_URL.FACEBOOK, name: 'facebook' },
  { id: 2, icon: <ImYoutube2 />, link: WEB_URL.YOUTUBE, name: 'youtube' }
];
