import { useEffect, useState } from 'react';
import ContentLoader from 'src/components/content-loader';

import { API_URI, APP_IMG } from 'src/constants';
import Layout from 'src/components/layouts';
import SEO from 'next-seo.config';
import { NextSeo } from 'next-seo';
import CardV from 'src/components/cards/card-v';
import styled from 'styled-components';

const mockArray = [{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }];

const PageError = ({ statusCode, sessionSevId, ads }) => {
  const [dataLatestNews, setDataLatestNews] = useState(null);
  useEffect(async () => {
    const res = await fetch(`${API_URI}/api/v1.0/categories/latest-news-by-one`);
    const resJson = await res.json();
    if ('msg' in resJson) {
      await setDataLatestNews(null);
    } else {
      await setDataLatestNews(resJson);
    }
  }, []);

  // console.log('==========> SOMETHING WRONG', statusCode);
  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo noindex={true} nofollow={true} {...SEO} />
      <Layout sessionSevId={sessionSevId} ads={ads}>
        <PageErrorStyle className='page-404'>
          <section className='container'>
            <div className='error-box text-center'>
              <img src={`${APP_IMG}/images/cropped-khobsanam-logo-1.png`} loading='lazy' alt='no-article' title='no-article' width='100%' />
              <div className='mt-15'>
                <a href='/' className='btn-error' aria-label='Error' title='error'>
                  กลับสู่หน้าหลัก
                </a>
              </div>
            </div>
            <div className='row error-news'>
              {dataLatestNews !== null ? (
                <>
                  {dataLatestNews?.data.slice(0, 6).map((item, index) => (
                    <div key={item.id} className='col-6 col-md-4 mt-30'>
                      <CardV data={item} path={item.link} />
                    </div>
                  ))}
                </>
              ) : (
                mockArray.map(item => (
                  <div key={item.id} className='col-6 col-md-4'>
                    <ContentLoader />
                  </div>
                ))
              )}
            </div>
          </section>
        </PageErrorStyle>
      </Layout>
    </>
  );
};

const PageErrorStyle = styled.div`
  padding: 30px 0;

  .text-center {
    text-align: center;
  }
  .btn-error {
    font-size: 24px;
    border-bottom: 2px solid #00ff57;
    &:hover {
      border-bottom: 2px solid #01af3b;
    }
  }

  .error-box {
    margin: auto;
    width: 100%;
    max-width: 300px;
    img {
      opacity: 0.5;
    }
  }
  .no-article {
    margin: 100px auto;
    opacity: 0.3;
    -webkit-filter: grayscale(40%);
    filter: grayscale(40%);
    @media (max-width: 475px) {
      margin: 38px auto;
    }
  }
`;

export default PageError;
